import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "store";
import { helpers } from "services";
import logo from "assets/images/logo.svg";
import { useHooks } from "hooks";
import { useMediaQuery } from "react-responsive";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

interface MenuItem {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
  route?: string;
}

const Navigation2: React.FC = () => {
  const { get, t, location } = useHooks();
  const { auth, toggleSidebar, system } = useStore((state) => state);
  const data = get(auth, "data");
  const pathSegments = location.pathname.split("/");
  const pathname = `/${pathSegments[1]}`;
  const filterKeys = get(data, "role.access", []).map(
    (item: any) => item.value
  );
  const isMobile = useMediaQuery({ maxWidth: 768 });
  useEffect(() => {
    if (isMobile) {
      toggleSidebar(); // Close the sidebar on mobile by default
    }
  }, [isMobile, toggleSidebar]);
  const filterMenuItems = (
    menuItems: MenuItem[],
    filterKeys: string[]
  ): MenuItem[] => {
    return menuItems.reduce((filteredItems: MenuItem[], item) => {
      if (filterKeys.includes(item.key)) {
        filteredItems.push(item);
      } else if (item.children) {
        const filteredChildren = item.children.filter((child) =>
          filterKeys.includes(child.key)
        );
        if (filteredChildren.length > 0) {
          filteredItems.push({ ...item, children: filteredChildren });
        }
      }
      return filteredItems;
    }, []);
  };

  return (
    <div className="h-full bg-no-repeat bg-cover bg-left">
      <div className="flex justify-between px-4 text-center items-center cursor-pointer pt-[25px] pb-[25px] text-[#9EA3B5]">
        <Link to="/">
          <img className="w-[50px]" src={logo} alt="logo" />
        </Link>
        {isMobile && <button className="close-open-btn" onClick={toggleSidebar}>
        {!system.isSidebarOpen ? <MenuOutlined /> : <CloseOutlined />}
      </button>}
      </div>
      <div className={"max-h-[75vh] overflow-y-scroll no-scrollbar"}>
        {filterMenuItems(helpers.menuItems, filterKeys).map((menuItem, i) => (
          <React.Fragment key={menuItem.key + i}>
            <Link
            onClick={toggleSidebar}
              //@ts-ignore
              to={get(menuItem, "route")}
              className={
                pathname === menuItem.route
                  ? "navbar-menuitem navbar-menuitem-actived"
                  : "navbar-menuitem"
              }
            >
              <div>{menuItem.icon}</div>
              {/* @ts-ignore */}
              <p>{t(get(menuItem, "label"))}</p>
            </Link>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Navigation2;

{
  /* {helpers.menuItems.map((menuItem, i) => ( */
}
