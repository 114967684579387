import {
  AppstoreOutlined,
  TranslationOutlined,
  FormOutlined,
  OrderedListOutlined,
  BellOutlined,
  CarOutlined,
  TeamOutlined,
  SettingOutlined,
  ToolOutlined,
  BuildOutlined,
  AlertOutlined,
  UserAddOutlined,
  AppstoreAddOutlined,
  ContactsOutlined,
} from "@ant-design/icons";

interface MenuItem {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
  route?: string;
}

// const formatStatus = (id: any) => {
//   if (id == 0) {
//     return "Yaratilgan";
//   } else if (id == 1) {
//     return "To'langan";
//   } else if (id == 2) {
//     return "Yig'ilgan";
//   } else if (id == 3) {
//     return "Yetkazilmoqda";
//   } else if (id == -9) {
//     return "Yaratilgan (Bekor qilingan)";
//   } else if (id == -1) {
//     return "To'langan (Bekor qilingan)";
//   } else if (id == -2) {
//     return "Yig'ilgan (Bekor qilingan)";
//   } else if (id == -3) {
//     return "Yetkazilmoqda (Bekor qilingan)";
//   } else {
//     return "Yetkazildi";
//   }
// };

const menuItems: MenuItem[] = [
  {
    key: "categories",
    label: "Kategoriyalar",
    icon: <AppstoreAddOutlined />,
    route: "/categories",
  },
  {
    key: "subcategories",
    label: "Subkategoriyalar",
    icon: <AppstoreAddOutlined />,
    route: "/subcategories",
  },
  {
    key: "services",
    label: "Xizmatlar",
    icon: <SettingOutlined />,
    route: "/services",
  },
  {
    key: "catalogs",
    label: "Kataloglar",
    icon: <AppstoreOutlined />,
    route: "/catalogs",
  },
  {
    key: "products",
    label: "Mahsulotlar",
    icon: <AlertOutlined />,
    route: "/products",
  },
  {
    key: "servicesdo",
    label: "Doimiy xizmatlar",
    icon: <ToolOutlined />,
    route: "/servicesdo",
  },
  {
    key: "cars",
    label: "Avtomobillar",
    icon: <CarOutlined />,
    route: "/cars",
  },
  {
    key: "users",
    label: "Foydalanuvchilar",
    icon: <UserAddOutlined />,
    route: "/users",
  },
  {
    key: "addservice",
    label: "Xizmat qo'shish",
    icon: <AppstoreAddOutlined />,
    route: "/add-service",
  },
  {
    key: "addproduct",
    label: "Mahsulot qo'shish",
    icon: <AppstoreAddOutlined />,
    route: "/add-product",
  },
  {
    key: "balance",
    label: "Balans",
    icon: <AppstoreAddOutlined />,
    route: "/balance",
  },
  {
    key: "branches",
    label: "Filiallar",
    icon: <BuildOutlined />,
    route: "/branches",
  },
  {
    key: "guidelines",
    label: "Qo'llanmalar",
    icon: <OrderedListOutlined />,
    route: "/guidelines",
  },
  {
    key: "notifications",
    label: "Bildirishnomalar",
    icon: <BellOutlined />,
    route: "/notifications",
  },
  {
    key: "blogs",
    label: "Bloglar",
    icon: <FormOutlined />,
    route: "/blogs",
  },
  {
    key: "contacts",
    label: "Aloqa",
    icon: <ContactsOutlined />,
    route: "/contacts",
  },
  {
    key: "employees",
    label: "Ishchilar",
    icon: <TeamOutlined />,
    route: "/employees",
  },
  {
    key: "roles",
    label: "Rollar",
    icon: <ContactsOutlined />,
    route: "/roles",
  },
  {
    key: "translations",
    label: "Tarjimalar",
    icon: <TranslationOutlined />,
    route: "/translations",
  },
];

function gen4() {
  return Math.random()
    .toString(16)
    .slice(-4);
}

export default {
  menuItems,
  // formatStatus,
};

export { gen4 };
