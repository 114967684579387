import { Container } from "modules";
import { Fields, Button } from "components";
import { FastField } from "formik";
import { useHooks } from "hooks";
import useStore from "store";

const Login = () => {
  const { get, t } = useHooks();
  const { signIn } = useStore((state) => state);
  return (
    <section className="flex justify-center items-center h-screen">
      <Container.Form
        url="admins/login"
        method="post"
        fields={[
          {
            name: "username",
            type: "string",
            required: true,
          },
          {
            type: "string",
            name: "password",
          },
        ]}
        onSuccess={(response) => {
          signIn({
            token: get(response, "data.token"),
            data: {
              username: get(response, "data.username"),
              role: "admin",
            },
          });
          window.location.reload()
        }}
        onError={(error) => {
        }}
      >
        {({ isLoading, setFieldTouched }) => {
          return (
            // <div>
            //   <h1 className="text-center text-[#000000DE] text-[32px] font-[600] mb-[8px]">
            //     {t("Welcome to")}{" "}
            //     <span className="text-[#002855]"> {t("Admin Dashboard")}</span>
            //   </h1>
            //   <p className="text-center text-[20px] text-[#9EA3B5] mb-[48px]">
            //     {t("Please enter your admin credentials to login")}
            //   </p>
            //   <FastField
            //     name="username"
            //     rootClassName="mb-7"
            //     isLoginPage={true}
            //     placeholder={t("Login")}
            //     component={Fields.Input}
            //     setFieldTouched={setFieldTouched}
            //   />
            //   <FastField
            //     name="password"
            //     type="password"
            //     rootClassName="mb-7"
            //     component={Fields.Password}
            //     placeholder={t("Password")}
            //   />
            //   <Button
            //     size="large"
            //     htmlType="submit"
            //     isLoading={isLoading}
            //     className="w-full h-auto"
            //     title={isLoading ? "Please wait a second" : "Log in"}
            //   />
            // </div>
            <div className="px-4 sm:px-6 md:px-8 lg:px-10">
              <h1 className="text-center text-[#000000DE] text-[24px] sm:text-[28px] md:text-[32px] font-[600] mb-[4px] sm:mb-[6px] md:mb-[8px]">
                {t("Welcome to")}{" "}
                <span className="text-[#002855]"> {t("Admin Dashboard")}</span>
              </h1>
              <p className="text-center text-[16px] sm:text-[18px] md:text-[20px] text-[#9EA3B5] mb-[24px] sm:mb-[36px] md:mb-[48px]">
                {t("Please enter your admin credentials to login")}
              </p>
              <FastField
                name="username"
                rootClassName="mb-4 sm:mb-5 md:mb-7"
                isLoginPage={true}
                placeholder={t("Login")}
                component={Fields.Input}
                setFieldTouched={setFieldTouched}
              />
              <FastField
                name="password"
                type="password"
                rootClassName="mb-4 sm:mb-5 md:mb-7"
                component={Fields.Password}
                placeholder={t("Password")}
              />
              <Button
                size="large"
                htmlType="submit"
                isLoading={isLoading}
                className="w-full h-auto"
                title={isLoading ? "Please wait a second" : "Log in"}
              />
            </div>
          );
        }}
      </Container.Form>
    </section>
  );
};

export default Login;
